<template>
    <div id="Kyc">
        <div class="container">
            <h6 class="form_title main_txt px-3">
                <i class="far fa-shield-alt"></i>
                {{$t("kyc.46")}}
            </h6>
            <div class="status">
                <div v-if="status==='H'" class="p-3">
                    <i class="far fa-file-search main_txt mb-4"></i>
                    <h6>KYC {{$t("kyc.47")}}</h6>
                    <p>{{$t("kyc.48")}}</p>
                </div>
                <div v-else-if="status==='Y'" class="p-3">
                    <i class="fal fa-copy main_txt mb-4"></i>
                    <h6>KYC {{$t("kyc.49")}}</h6>
                    <p>{{$t("kyc.50")}}</p>
                    <!-- <router-link :to="`/${$i18n.locale}/page/kyc`" class="btn btn-primary w-100">수정</router-link> -->
                </div>
                <div v-else class="p-3">
                    <i class="far fa-file-times main_txt mb-4"></i>
                    <h6>KYC {{$t("kyc.51")}}</h6>
                    <p>{{$t("kyc.52")}}</p>
                    <router-link :to="`/${$i18n.locale}/page/kyc/edit`" class="btn btn-primary w-100">{{$t("kyc.53")}}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title: this.$t("kyc.1"),
            status: this.$route.params.check,
            prevP: true,
            pageD: false,
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    methods:{           
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '200'){                     
                        this.CheckKYC();
                    }else if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },
        CheckKYC(){
            const api = this.$store.state.baseURL;
			this.$http.post(`${api}/member/wallet/kyc/check`,{}).then(
			res => {
				if(res.status==200){
                    if(res.data.code == '100'){
                        const status = res.data.check.status;
                        this.status = status;
                    }
				}
			}).finally(() => {
                this.save = false;
            })
        },       
    }
}
</script>

<style scoped>
.status i{
    font-size: 2.5em;
    display: flex;
    border-radius: 12px;
    width: 100%;
    height: 100px;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    /* background-color: #6276c30d; */
    background-color: #2e9cd70d;
}
.status p,li{
    font-size: 12px;
    color: #777;
}
</style>