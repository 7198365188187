<template>
    <div id="Kyc">
        <div class="container">
            <vue-element-loading :active="save" spinner="bar-fade-scale"/>
            <div class="step1" v-show="step1">
                <h6 class="form_title main_txt">
                    <i class="far fa-edit"></i>
                    {{$t('kyc.21')}}
                </h6>
                <div class="form_box">
                    <div class="form-group line">
                        <label class="w-100 mb-2">{{$t('kyc.2')}}</label>
                        <input type="text" class="form-control" v-model="name" :placeholder="$t('signup.3')" required>
                    </div>
                    <div class="form-group line">
                        <label class="w-100 mb-2">{{$t('kyc.5')}}</label>
                        <input type="text" class="form-control" v-model="phone" :placeholder="$t('signup.8')" required>
                    </div>
                    <div class="form-group line">
                        <label class="w-100 mb-2">{{$t('kyc.7')}}</label>
                        <select class="form-control" :placeholder="$t('signup.3')" v-model="nationality" required>
                            <option :value="item.nationality" v-for="(item, index) in nationalitys" :key="index">{{item.nationality}}</option>
                        </select>
                    </div>
                    <div class="form-group line">
                        <label class="w-100 mb-2">{{$t('kyc.11')}}</label>
                        <input type="text" class="form-control" v-model="post_code" :placeholder="$t('signup.3')" @focus="post = true" required>
                        <vue-daum-postcode v-if="post" @complete="AddressIn($event)"/>
                    </div>
                    <div class="form-group line">
                        <label class="w-100 mb-2">{{$t('kyc.8')}}</label>
                        <input type="text" class="form-control" v-model="address" :placeholder="$t('signup.3')" @focus="post = true" required>
                    </div>
                    <div class="form-group line">
                        <label class="w-100 mb-2">{{$t('kyc.9')}}</label>
                        <input type="text" class="form-control" v-model="detail_addres" :placeholder="$t('kyc.25')">
                    </div>
                </div>
                <button class="btn btn-primary w-100" @click="Chk()">{{$t('kyc.26')}}</button>
            </div>
            <div class="step2" v-if="step2">
                <h6 class="form_title main_txt">
                    <i class="far fa-file-certificate"></i>
                    {{$t('kyc.23')}}
                </h6>
                <p><i class="fas fa-info-square mr-1 main_txt"></i>{{$t('kyc.12')}}</p>
                <div class="flex justify-content-between mb-3 icon">
                    <button class="btn w-50 mr-2 main_txt" :class="{active: check===true}" @click="check=true"><i class="fal fa-passport"></i>{{$t('kyc.13')}}</button>
                    <button class="btn w-50 main_txt" :class="{active: check===false}" @click="check=false"><img src="@/assets/img/icon/id-card.svg" style="display: block; margin: 0 auto 13px;" height="50" alt="">{{$t('kyc.14')}}</button>
                </div>
                <p>{{$t('kyc.15')}}</p>
                <ul class="mb-2">
                    <li><i class="far fa-check mr-1"></i>{{$t('kyc.16')}}</li>
                    <li><i class="far fa-check mr-1"></i>{{$t('kyc.17')}}</li>
                    <li><i class="far fa-check mr-1"></i>{{$t('kyc.18')}}</li>
                </ul>
                <p class="main_txt" v-show="check===true">{{$t('kyc.19')}}</p>
                <p class="main_txt" v-show="check===false">{{$t('kyc.30')}}</p>
                <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-success="vsuccess" @vdropzone-error="vsmaxfile" class="mb-3"></vue-dropzone>
                <button class="btn btn-primary w-100" @click="FinalChk()" :disabled="!finish">{{$t('kyc.27')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import AWS from 'aws-sdk';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
const data = require('npm-nationality-list')

export default {
    components:{
        vueDropzone: vue2Dropzone
    },
    data(){
        return{
            title: this.$t("kyc.1"),      
            lang: this.$route.params.lang,  
            nationalitys: data.getList(),

            name: "",
            phone: "",
            nationality: "South Korean",
            post_code: "",
            address: "",
            detail_addres: "",
            image: {},
            imageUrl: "",
            
            AWSURL: this.$store.state.AWS.URL,
            albumBucketName : this.$store.state.AWS.albumBucketName,
            bucketRegion : this.$store.state.AWS.bucketRegion,
            IdentityPoolId : this.$store.state.AWS.IdentityPoolId,

            post: false,
            prevP: true,
            pageD: false,
            step1 : true,
            step2: false,
            check: true,
            finish: false,
            save: false,
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFiles:1,
                maxFilesize: 0.5,
                addRemoveLinks: true,
                dictDefaultMessage: "<i class='fa fa-cloud-upload mr-2'></i>UPLOAD"
            }
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        this.Token_Check();
    },
    methods:{         
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },
        AddressIn(event){
            this.post = false;
            this.post_code = event.zonecode
            if(this.lang == "ko"){
                this.address = event.roadAddress
            }
            else{
                this.address = event.roadAddressEnglish                
            }
        },
        Chk(){
            var check = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/.test(this.phone);
            if(this.name == ""){
                return this.$alert(this.$t("kyc.35"));
            }
            else if(this.phone == ""){
                return this.$alert(this.$t("kyc.36"));
            }
            else if(!check){
                return this.$alert(this.$t("kyc.37"))
            }
            else if(this.nationality == ""){
                return this.$alert(this.$t("kyc.38"));
            }
            else if(this.post_code == ""){
                return this.$alert(this.$t("kyc.39"));
            }
            else if(this.address == ""){
                return this.$alert(this.$t("kyc.40"));
            }
            else if(this.detail_addres == ""){
                this.$confirm(this.$t("kyc.41")).then(() => {                    
                    this.step1 = false
                    this.step2 = true
                }).catch(() => {
                    return;
                })
            }
            else{
                this.step1 = false
                this.step2 = true
            }
        },
        FinalChk(){
            this.save = true;
            const files = this.image;
            const date = new Date();
            const timestamp = date.getTime();
            const fileName = timestamp + files.name;

            AWS.config.update({region: this.bucketRegion,credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: this.IdentityPoolId})});
            var s3 = new AWS.S3({apiVersion: "2006-03-01",params: { Bucket: this.albumBucketName + '/kyc'}});
            s3.upload({Key: fileName, Body : files, ACL:'public-read','ContentType': files.type}, (err)=>{
                if(err == null){
                    this.imageUrl = this.AWSURL + "kyc/" +fileName;
                    this.EditKYC();
                }
                else{
                    this.save = false;
                    this.$alert(this.$t("kyc.42")).then(() => {
                        this.$router.go(0);
                    });
                }
            })
        },
        EditKYC(){
            const name = this.name;
            const phone = this.phone;
            const nation = this.nationality;
            const post_code = this.post_code;
            const address = this.address + " " + this.detail_addres;
            var type = "";
            if(this.check) type = this.$t("kyc.43");
            else type = this.$t("kyc.44");
            const imageUrl = this.imageUrl;

            const api = this.$store.state.baseURL;
			this.$http.post(`${api}/member/wallet/kyc/edit`,{name, phone, nation, post_code, address, type, imageUrl}).then(
			res => {
				if(res.status==200){
                    if(res.data.code == '200'){
                        this.$router.push('/'+this.$i18n.locale+ '/page/kyc/status/H');
                    }				
				}
			}).finally(() => {
                this.save = false;
            })
        },
        vsuccess(file, response){
            this.image = file;
            this.finish = true;
        },
        vsmaxfile(file,message,xhr){
            this.$refs.myVueDropzone.removeFile(file);
            this.$alert(this.$t("kyc.45"));
        }
    }
}
</script>

<style scoped>
.vue-dropzone{
    border-radius: 12px;
    border: 3px solid #36379560;
}
.form_title{
    padding: 0 20px 10px 10px;
    margin-bottom: 1em;
    background-color: rgb(98 118 195 / 6%);
    padding: 1em;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
}
.form_title i{
    font-size: 1.1em;
}
.step2 p,.step2 li{
    font-size: 12px;
    color: #777;
}
.step2 .icon i{
    display: block;
    font-size: 3em;
    margin: 10px;
    color: #363795;
}
.step2 .icon button{
    border-radius: 12px;
    border: 3px solid #363795;
    opacity: 0.3;
    font-weight: 600;
    letter-spacing: 1px;
    outline: none;
}
.btn:focus, .btn.focus{
    box-shadow: none;
}
.step2 .icon button.active{
    opacity: 1;
}
</style>