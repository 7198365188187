<template>
    <div id="terms">
        <div class="container">
            <div class="form_box">
                <p v-html="sanitizeHtml($t('term.2'))" style="font-weight: bold;">
                <p v-html="sanitizeHtml($t('term.3'))">
                <p v-html="sanitizeHtml($t('term.4'))" style="font-weight: bold;">
                <p v-html="sanitizeHtml($t('term.5'))">
                <p v-html="sanitizeHtml($t('term.6'))" style="font-weight: bold;">
                <p v-html="sanitizeHtml($t('term.7'))">
                <p v-html="sanitizeHtml($t('term.8'))" style="font-weight: bold;">
                <p v-html="sanitizeHtml($t('term.9'))">
                <p v-html="sanitizeHtml($t('term.10'))" style="font-weight: bold;">
                <p v-html="sanitizeHtml($t('term.11'))">
                <p v-html="sanitizeHtml($t('term.12'))" style="font-weight: bold;">
                <p v-html="sanitizeHtml($t('term.13'))">
                <p v-html="sanitizeHtml($t('term.14'))" style="font-weight: bold;">
                <p v-html="sanitizeHtml($t('term.15'))">
                <p v-html="sanitizeHtml($t('term.16'))" style="font-weight: bold;">
                <p v-html="sanitizeHtml($t('term.17'))">
                <p v-html="sanitizeHtml($t('term.18'))" style="font-weight: bold;">
                <p v-html="sanitizeHtml($t('term.19'))">
                <p v-html="sanitizeHtml($t('term.20'))" style="font-weight: bold;">
                <p v-html="sanitizeHtml($t('term.21'))">
                <p v-html="sanitizeHtml($t('term.22'))" style="font-weight: bold;">
                <p v-html="sanitizeHtml($t('term.23'))">
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html';
export default {
    data(){
        return{
            title:this.$t("term.1"),
            prevP:true,
            pageD: false,
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
    },
    methods: {    
        sanitizeHtml : sanitizeHtml,
    },
}
</script>

<style scoped>
#terms p{
    font-size: .8em;
}
</style>